<template>
  <div class="look-phone">
    <el-button icon="el-icon-phone" size="medium" type="primary" @click.stop="lookPhone()">查询联系方式
    </el-button>
    <!-- 查看联系方式弹出框 -->
    <el-dialog :visible.sync="lookPhoneDialog" width="30%" center :show-close="false">
      <span slot="title">
        <i class="fa fa-info-circle" style="color: #e1251b; font-size: 18px"></i>
        <span> 查看联系方式，请前往登录 </span>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click.stop="lookPhoneDialog = false" size="medium ">取 消</el-button>
        <el-button class="l-btn" @click.stop="toLogin()" size="medium">登录</el-button>
      </span>
    </el-dialog>
    <!-- 购买简历弹框 -->
    <el-dialog :visible.sync="resumeDialog" width="30%" center :show-close="false">
      <div slot="title">
        <div>
          <i class="fa fa-info-circle" style="color: #ff7125; font-size: 18px"></i>
          您确定要购买该人员的简历吗？购买简历需扣除
          <span style="color: #ff7125; font-size: 18px">{{ totalPrice }}个积分</span>
          <br />
          <br />
          <span class="d-txt">已购买的简历保存10天，到期后凌晨清空</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.stop="resumeDialog = false" size="medium">取消</el-button>
        <el-button type="info" size="medium" @click.stop="confirmContactInfo()">确定</el-button>
      </span>
    </el-dialog>
    <!-- /弹框 -->

    <!-- 余额不足弹框 -->
    <el-dialog :visible.sync="balanceDialog" width="30%" center :show-close="false">
      <span slot="title" class="balanceColor">
        <i class="fa fa-info-circle" style="color: #ff7125; font-size: 18px"></i>
        {{ balanceTitle }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click.stop="balanceDialog = false" size="medium ">取消</el-button>
        <el-button type="info" size="medium" @click.stop="$router.push('/pc/recharge')">充值</el-button>
      </span>
    </el-dialog>
    <!-- /弹框 -->
  </div>
</template>
<script>
  import {
    getContactInfoApi
  } from '@/api/talentPool'
  export default {
    name: 'lookPhone',
    props:{
      userId:{
      },
      totalPrice:{
        type: Number,
        default: 2
      }
    },
    data() {
      return {
        lookPhoneDialog: false,
        resumeDialog: false,
        balanceDialog: false,
        balanceTitle: '',
      }
    },
    methods: {
      //去登录
      toLogin() {
        //打开登录弹出框
        this.$store.commit('saveloginStatus', 1)
        this.$store.commit('saveloginDialog', true)
        this.lookPhoneDialog = false
      },
      // 查看联系方式
      lookPhone() {
        const tokenStr = localStorage.getItem('school_token')
        if (!tokenStr) {
          this.lookPhoneDialog = true
        } else {
          this.resumeDialog = true
        }
      },
      //确定后
      async confirmContactInfo() {
        let userIdArr = []
        if (this.userId.constructor === Array) {
          userIdArr = this.userId
        } else {
          userIdArr.push(this.userId)
        }
        const {
          data: res
        } = await getContactInfoApi({
          id: this.userId
        })
        if (res.status == 90004) {
          this.resumeDialog = false
          this.balanceDialog = true
          this.balanceTitle = res.message
        } else if (res.status == 10000) {
          this.$message.success('购买成功,请前往已购简历中心查看')
          this.resumeDialog = false
          this.$emit('refreshCollectionList')
        } else {
          this.$message.error(res.message)
        }
      },
    },
  }

</script>

<style lang="less" scoped>
  .el-button {
    font-size: 15px;
  }

  .d-txt {
    color: #ff7125;
  }

  .balanceColor {
    color: #ff7125;
  }

  .dialog-footer {
    .l-btn {
      background: @primaryColor;
      color: #fff;
    }
  }

  .look-phone{
    text-align: center;
  }

</style>
