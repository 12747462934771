import request from '@/libs/request'

/**
 * 获取保安员联系方式
 */
export function getContactInfoApi(data) {
    return request({
        url: '/company/obtainUserPhone',
        method: 'post',
        data
    })
}

/**
 * 获取已购买的保安联系方式
 */
export function getBuyStdInfoApi(data, page, limit) {
    return request({
        url: `/company/buylist?page=${page}&limit=${limit}`,
        method: 'post',
        data
    })
}

/**
 * 学员列表下拉选择条件搜索/学员列表初始化
 */
export function dropDownSearchStudentApi(data, page, limit) {
    return request({
        url: `/userInfo/searchStaff?page=${page}&limit=${limit}`,
        method: 'post',
        data
    })
}

/**
 * 学员详情
 */
export function getStdDetailDataApi(user_id) {
    return request({
        url: `userInfo/staffInfo?id=${user_id}`,
        method: 'get'
    })
}
/**
 * 收藏学员
 */
export function collectStdInfoApi(id) {
    return request({
        url: `/company/collAdd/${id}`,
        method: 'get'
    })
}